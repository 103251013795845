

































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Pager } from "@/types/Pager";
import { loadPromos } from "@/api/promos";
import { Promo } from "@/types/Promo";

@Component({
  components: {}
})
export default class Users extends Vue {
  data: Promo[] = [];
  pagination: Partial<Pager> = {};
  isLoading = false;
  isLoadPagination = false;

  columns = [
    {
      title: "Code",
      key: "code",
      dataIndex: "code"
    },
    {
      title: "Discount",
      key: "discount",
      dataIndex: "discount",
      scopedSlots: { customRender: "discount" }
    },
    {
      title: "Range",
      key: "range",
      dataIndex: "range",
      scopedSlots: { customRender: "range" }
    },
    {
      title: "Multiple",
      key: "multiple",
      dataIndex: "multiple",
      scopedSlots: { customRender: "multiple" }
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      scopedSlots: { customRender: "status" }
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "20%",
      scopedSlots: { customRender: "action" }
    }
  ];
  searchCode = "";
  debouncedLoadCodes = this.lodash.debounce(this.loadPromos, 500);

  mounted() {
    this.loadPromos(1);
  }

  handleTableChange(pagination: Pager) {
    const pager: Partial<Pager> = { ...this.pagination };
    pager.current = pagination.current;
    this.pagination = pager;
    if (pager.current) {
      this.loadPromos(pager.current);
    }
  }

  async loadPromos(page: number) {
    this.isLoading = true;
    const { data } = await loadPromos(page, this.searchCode);
    this.data = data.data;
    this.isLoading = false;
    this.pagination.current = data.currentPage;
    this.pagination.pageSize = data.perPage;
    this.pagination.total = data.total;
    this.isLoadPagination = true;
  }

  colorTag = (status: boolean) => (status ? "green" : "red");

  @Watch("searchCode")
  codeSearch() {
    this.debouncedLoadCodes(1);
  }
}

import ApiService from "@/api/api.service";
import { AxiosPromise } from "axios";
import { Promo } from "@/types/Promo";
import { PaginationResponse } from "@/types/PaginationResponse";

export const loadPromo = (id: number): AxiosPromise<Promo> => {
  return ApiService.get(`api/admin/promos/${id}`);
};

export const loadPromos = (
  page: number,
  code: string
): AxiosPromise<PaginationResponse<Promo[]>> => {
  return ApiService.query("api/admin/promos", {
    page,
    code
  });
};

export const createPromo = (promo: Promo): AxiosPromise<Promo> => {
  return ApiService.post("api/admin/promos", promo);
};

export const updatePromo = (id: number, promo: Promo): AxiosPromise<Promo> => {
  return ApiService.put(`api/admin/promos/${id}`, promo);
};
